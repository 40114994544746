body, html {
 position: fixed;
 background: black;
 margin: 0;
 padding: 0;
 width: 100%;
 height: 100%;
 overflow: hidden;
}
canvas {
 position: absolute;
 background: black;
 z-index: -1;
 top: 0;
 left: 0;
}
#tex {
 width: 100%;
 font-size: 30px;
 background: black;
 color: white;
}
